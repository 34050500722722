import React from "react";
import moment from 'moment';
import { Modal, Pagination, Table, Input, Form, Select, Alert, Tag, InputNumber, Tooltip, Button} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default class PackagesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            detailBanking: null
        };
        this.limit = 10;
        this.page = 1;
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async () => {
        try {
            this.setState({ loading: true });
            let response = await this.props.fetchData({
                url: `api/v1/transaction/list?limit=${this.limit}&page=${this.page}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                type: "error",
                title: "get data",
                content: err.message || "An error occurred"
            })
        }
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.reloadPayment && nextProps.reloadPayment != this.props.reloadPayment) {
            this.getListData();
        }
    }
    create = async (values) => {
        try {
            this.setState({ loadingForm: true });
            let response = await this.props.fetchData({
                url: `api/v1/transaction/create`,
                method: 'post',
                data: values
            });
            this.getListData();
            this.setState({
                loadingForm: false,
                error: null,
                visibleForm: false
            }, () => {
                this.props.showDetailPayment(response.data)
            })
        } catch (err) {
            this.setState({ loadingForm: false, error: err.message || "An error occurred" });
        }
    }
    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <Modal
                    title="Nạp tiền"
                    visible={this.state.visibleForm}
                    onCancel={() => this.setState({ visibleForm: false }, () => this.form.resetFields())}
                    footer={null}
                >
                    <Form
                        onFinish={this.create}
                        ref={(evt) => this.form = evt}
                        initialValues={{
                            amount: 50000
                        }}
                    >
                        {
                            this.state.error ? <Alert type="error" message={this.state.error} /> : null
                        }
                        <Form.Item
                            name="amount"
                            label="Số tiền"
                        >
                            <Input type="number" style={{width: "100%"}} addonAfter="VND"/>
                        </Form.Item>

                        <div className="text-right">
                            <Button type="primary" loading={this.state.loadingForm} htmlType="submit">
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Quản lý giao dịch</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex mr-2`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Làm mới">
                                    <Button className="mr-2" type="primary" onClick={() => this.getListData()}>
                                        <i className="far fa-refresh"></i>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Nạp tiền">
                                    <Button className="mr-2" type="primary" onClick={() => this.setState({ visibleForm: true })}>
                                        Nạp tiền
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "#",
                                        index: "#",
                                        render: (item, value, index) => {
                                            return (this.page * this.limit - this.limit) + index + 1
                                        }
                                    },
                                    {
                                        title: "Mã",
                                        dataIndex: "code"
                                    },
                                    {
                                        title: "Số tiền",
                                        dataIndex: "amount",
                                        render: (item) => this.props.formatPrice(item)
                                    },
                                    {
                                        title: "Trạng thái",
                                        render: (item) => {
                                            return <Tag color={item.status == 0 ? "processing" : item.status == 1 ? "success" : "error"}>{item.status == 0 ? "Pending" : item.status == 1 ? "Success" : "Failed"}</Tag>
                                        }
                                    },
                                    {
                                        title: "Ngày thanh toán",
                                        render: (item) => {
                                            return item.time_payment ? moment(item.time_payment).format('YYYY/MM/DD HH:mm') : null
                                        }
                                    },
                                    {
                                        title: "Ngày tạo",
                                        render: (item) => {
                                            return moment(item.created_time).format('YYYY/MM/DD HH:mm')
                                        }
                                    },
                                    {
                                        title: "Hành động",
                                        className: "text-right",
                                        render: (item) => {
                                            return <div>
                                                <Tooltip title="View">
                                                    <Button size="small" type="primary" onClick={() => {
                                                        this.props.showDetailPayment(item)
                                                    }}><i className="far fa-eye"></i></Button>
                                                </Tooltip>
                                            </div>
                                        }
                                    }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: false
                                }}
                                rowKey="_id"
                                // rowSelection={{
                                //     type: "checkbox",
                                //     selectedRowKeys: this.state.dataSelected,
                                //     onChange: (values) => {
                                //         this.setState({
                                //             dataSelected: values
                                //         })
                                //     }
                                // }}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
