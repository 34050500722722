import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class uploadNick extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            loading: false
        }
        this.form = null;
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true });

            if (!values.upload) {
                this.props.notification({
                    message: 'Lỗi upload',
                    description: 'Bạn chưa chọn file!',
                    type: 'error'
                })
                return;
            }
            let formData = new FormData();
            formData.append('file', values.upload.file);
            formData.append('format', values.format);

            let resutl = await this.props.fetchData({
                url: 'api/v1/nick/create/file',
                method: 'post',
                data: formData,
                formdata: true
            });
            this.setState({ loading: false });
            this.props.notification({
                title: "Cập nhập dữ liệu",
                content: "Thao tác thành công",
                type: "success"
            })
            this.props.getListData();

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({
                    title: "Cập nhập dữ liệu",
                    content: err.message || "Thao tác thất bại",
                    type: "error"
                })
            });
        }
    };
    onFileChange = ({ fileList }) => {
        this.setState({ fileList: [], file: fileList[0] });
    }
    render() {
        const { fileList } = this.state;
        return (
            <Modal
                title="Upload"
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
            >
                <Form
                    onFinish={this.onFinish}
                    ref={(evt) => this.form = evt}
                    initialValues={{
                        "format": ["uid", "cookie"],
                        "format_text": "uid | cookie"
                    }}
                >
                    <Form.Item name="upload" >
                        <Upload.Dragger name="files" beforeUpload={() => false} accept={'.txt'} fileList={fileList} onChange={this.onFileChange} showUploadList={false}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">{this.state.file ? this.state.file.name : 'Support for a single or bulk upload.'}</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <Form.Item name="format">
                        <Checkbox.Group style={{ width: "100%" }} onChange={(e) => { this.form.setFieldsValue({ format_text: e.join(' | ') }) }}>
                            <Row>
                                <Col span={8}>
                                    <Checkbox value="uid" style={{ lineHeight: '32px' }}>
                                        Uid
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="cookie" style={{ lineHeight: '32px' }}>
                                        Cookie
                                    </Checkbox>
                                </Col>

                                <Col span={8}>
                                    <Checkbox value="password" style={{ lineHeight: '32px' }}>
                                        Password
                                    </Checkbox>
                                </Col>

                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item
                        name="format_text"
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <div className="text-center">
                        <Button type="primary" loading={this.state.loading} htmlType="submit" disabled={!this.state.file ? true : false}>
                            Tiếp tục
                            </Button>
                    </div>
                </Form>
            </Modal>
        )
    }
}
