import React from "react";
import NumberFormat from 'react-number-format';
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';
import { UploadNick, UploadFile} from '../../component';

export default class ManageNick extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            listFolder: []
        };
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListFolder();
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await fetchData({
                url: `api/v1/nick/list?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    getListFolder = async (keyword = '') => {
        if(this.get_folder) clearTimeout(this.get_folder);
        this.get_folder = setTimeout(async () => {
            try {
                this.setState({ loadingGetFolder: true });
                let params = {
                    limit: 10,
                    keyword: keyword
                }
    
                let response = await fetchData({
                    url: `api/v1/nick/folder/list?${qs.stringify(params)}`,
                    method: 'get'
                });
    
                this.setState({
                    listFolder: response.data,
                    loadingGetFolder: false
                })
            } catch (err) {
                this.setState({ loadingGetFolder: false });
                this.props.notification({
                    title: "Lấy dữ liệu",
                    type: "error",
                    content: err.message || "Đã có lỗi xảy ra!"
                })
            }
        }, 300)
    }

    update = async (values) => {
        try {
            this.setState({ confirmLoading: true })
            let res = await fetchData({
                url: `api/v1/ow/user/${values.user_id}`,
                method: 'post',
                data: values
            });
            this.getListData();
            this.setState({ confirmLoading: false })
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập người dùng',
                type: 'success'
            })
        } catch (err) {
            this.setState({ confirmLoading: false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập người dùng',
                type: 'error'
            })
        }
    }

    delete = async (values) => {
        var { list_ids } = values;
        if (list_ids.length == 0) {
            this.props.notification({
                type: 'warning',
                title: 'Lỗi xóa',
                content: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${list_ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v1/nick/delete',
                            method: 'post',
                            data: {
                                ids: list_ids
                            },
                        });
                        notification({
                            type: 'success',
                            title: 'Xóa thành công',
                            content: `Bạn đã xóa thành công ${result.data.deletedCount} đối tượng!`
                        })
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            title: 'Lỗi xóa',
                            content: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }

    create = async (values) => {
        try {
            this.setState({ loadingForm: true });

            if (!values.upload) {
                this.props.notification({
                    message: 'Lỗi upload',
                    description: 'Bạn chưa chọn file!',
                    type: 'error'
                })
                return;
            }
            let formData = new FormData();
            formData.append('file', values.upload.file);
            formData.append('format', values.format);
            formData.append('folder_id', values.folder_id);

            let resutl = await this.props.fetchData({
                url: 'api/v1/nick/create/file',
                method: 'post',
                data: formData,
                formdata: true
            });
            this.setState({ loadingForm: false });
            this.props.notification({
                title: "Cập nhập dữ liệu",
                content: "Thao tác thành công",
                type: "success"
            })
            this.getListData();

        } catch (err) {
            this.setState({ loadingForm: false }, () => {
                this.props.notification({
                    title: "Cập nhập dữ liệu",
                    content: err.message || "Thao tác thất bại",
                    type: "error"
                })
            });
        }
    };

    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <UploadFile {...this.props} visible={this.state.visibleUpload} hide={() => this.setState({ visibleUpload: false })} 
                    format={["uid", "cookie", "password"]}
                    onFinish={this.create}
                    loading={this.state.loadingForm}
                    select_format={true}
                    listFolder={this.state.listFolder}
                    loadingGetFolder={this.state.loadingGetFolder}
                    getListFolder={this.getListFolder}
                />

                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Quản lý người dùng</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Xóa">
                                    <button type="button" className={`btn btn-danger btn-sm d-flex align-items-center ml-5`} onClick={() => {
                                        let list_ids = [];
                                        document.querySelectorAll('table input[name="check_item"]:checked').forEach((item) => list_ids.push(item.value));
                                        this.delete({
                                            list_ids: list_ids
                                        })
                                    }}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Thêm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.setState({ visibleUpload: true })}>
                                        Thêm mới
                                    </button>
                                </Tooltip>
                            </div>
                            <div className={`${this.state.loading ? 'data-loading' : ''}`}>
                                <Pagination
                                    total={this.state.total}
                                    defaultCurrent={1}
                                    showSizeChanger={true}
                                    pageSizeOptions={[20, 50, 100, 200, 500]}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getListData();
                                    }}
                                    onShowSizeChange={(current, size) => {
                                        this.limit = size;
                                        this.page = current;
                                        this.getListData();
                                    }}
                                />
                            </div>
                        </div>
                        <Form
                            initialValues={{
                                is_lock: "",
                                status: "",
                                folder_id: ""
                            }}
                            onChange={(e) => {
                                if (e.target.nodeName == 'INPUT') {
                                    if (this.timeout) clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        this.getListData('reset');
                                    }, 300)
                                } else {
                                    this.getListData();
                                }
                            }}
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row tool-filter">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="keyword">
                                        <Input placeholder="Uid" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="is_lock"
                                    >
                                        <Select onChange={(e) => {
                                            this.getListData('reset');
                                        }}>
                                            <Select.Option value="">Lock</Select.Option>
                                            <Select.Option value={1}>Đang sử dụng</Select.Option>
                                            <Select.Option value={0}>Đang mở</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="folder_id"
                                    >
                                        <Select 
                                            onChange={(e) => {
                                                this.getListData('reset');
                                            }}
                                            showSearch={true}
                                            loading={this.state.loadingGetFolder}
                                            filterOption={false}
                                            onSearch={this.getListFolder}
                                            // allowClear={true}
                                        >
                                            <Select.Option value="">Thư mục</Select.Option>
                                            {
                                                this.state.listFolder.map((item, i) => {
                                                    return <Select.Option value={item._id} key={i}>{item.name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="status"
                                    >
                                        <Select onChange={(e) => {
                                            this.getListData('reset');
                                        }}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={1}>Hoạt động</Select.Option>
                                            <Select.Option value={2}>Không hoạt động</Select.Option>
                                            <Select.Option value={3}>Cookie die</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Xóa lọc">
                                        <Button className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getData('reset');
                                        }}></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>
                        <div className="table-responsive">
                            <Spin spinning={this.state.loading} tip="loading..">
                                <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                    <thead>
                                        <tr>
                                            <th className="text-left">
                                                <label className="css-control css-control-primary css-checkbox p-0">
                                                    <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}
                                                    />
                                                    <span className="css-control-indicator"></span>
                                                </label>
                                            </th>
                                            <th>Folder</th>
                                            <th className="text-left">UID</th>
                                            <th>Password</th>
                                            <th className="text-left">Cookie</th>
                                            <th>Log</th>
                                            <th>Lock</th>
                                            <th>Trạng thái</th>
                                            <th>Hoạt động</th>
                                            <th>Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                return <tr key={item._id}>
                                                    <td className="text-left">
                                                        <label className="css-control css-control-primary css-checkbox p-0">
                                                            <input name="check_item" type="checkbox" className="css-control-input checkbox" value={item._id}
                                                                onChange={this.props.handleChecked}
                                                            />
                                                            <span className="css-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>{item.folder ? item.folder.map((item) => item.name).join(', ') : ""}</td>
                                                    <td className="text-left">{item.uid}</td>
                                                    <td>{item.password}</td>
                                                    <td>
                                                        <textarea className="form-control" style={{ maxWidth: "200px" }}>{item.cookie}</textarea>
                                                    </td>
                                                    <td>
                                                        <textarea className="form-control" style={{ maxWidth: "200px" }}>{item.log}</textarea>
                                                    </td>
                                                    <td>{item.is_lock ? 'true' : 'false'}</td>
                                                    <td>{item.status == 1 ? <span className="text-success">Hoạt động</span> : item.status == 2 ? <span className="text-danger">Không hoạt đông</span> : item.status == 3 ? <span className="text-danger">Cookie die</span> : <span>Chưa xác định</span> }</td>
                                                    <td>{item.last_time ? moment(item.last_time).format('HH:mm DD-MM-YYYY') : ''}</td>
                                                    <td>
                                                        <Tooltip title="Xóa">
                                                            <a href="#" className="btn btn-danger ml-5 p-5" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.delete({ list_ids: [item._id] })
                                                            }}>
                                                                <i class="far fa-trash"></i>
                                                            </a>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
