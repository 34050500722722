import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Select, Form, Input, InputNumber, Upload, Table } from 'antd';
import qs from 'qs';
import moment from 'moment';

export default class FolderNickView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
        };
        this.limit = 16;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == "reset") this.page = 1;
            let query = {
                limit: this.limit,
                page: this.page,
            }
            if (this.formFilter) {
                query = {
                    ...query,
                    ...this.formFilter.getFieldsValue()
                }
            }
            let response = await this.props.fetchData({
                url: `api/v1/nick/folder/list?${qs.stringify(query)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false })
        }
    }
    save = async (values) => {
        try {
            this.setState({ loadingForm: true });
            let url = `api/v1/nick/folder`;
            let method = 'post';

            if(values['_id']){
                url = `api/v1/nick/folder/${values['_id']}`;
                method = 'put';
            }
            let response = await this.props.fetchData({
                url: url,
                method: method,
                data: values
            })
            this.getListData();
            this.setState({ loadingForm: false, visibleForm: false }, () => {
                this.form.resetFields();
            });

            this.props.notification({
                type: "success",
                title: "Cập nhập folder",
                content: "Thao tác thành công"
            })
        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                type: "error",
                title: "Cập nhập folder",
                content: err.message || "Đã có lỗi xảy ra"
            })
        }
    }

    delete = async (ids = []) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await _this.props.fetchData({
                            url: 'api/v1/nick/folder/delete',
                            method: 'delete',
                            data: {
                                ids: ids
                            },
                        });
                        _this.props.notification({
                            type: 'success',
                            title: 'Xóa thành công',
                            content: `Bạn đã xóa thành công ${result.data.deletedCount} đối tượng!`
                        })
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        _this.props.notification({
                            type: 'error',
                            title: 'Lỗi xóa',
                            content: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }

    render() {
        let { listData } = this.state;

        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <Modal
                    title="Cập nhập folder"
                    visible={this.state.visibleForm}
                    onCancel={() => this.setState({ visibleForm: false }, () => this.form.resetFields())}
                    footer={null}
                >
                    <Form
                        onFinish={this.save}
                        ref={(evt) => this.form = evt}
                        initialValues={{

                        }}
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: 'không được để trống!' }]}
                            label="Tên"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="tags"
                            label="Tài nguyên sử dụng"
                        >
                            <Select
                                mode="multiple"
                                showArrow={true}
                            >
                                {
                                    this.props.service.type_order.map((item, i) => {
                                        return <Select.Option value={item.value} key={i}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <div className="text-right">
                            <Button type="primary" loading={this.state.loadingForm} htmlType="submit">
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Quản lý folder</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Xóa">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.setState({ visibleForm: true })}>
                                        Thêm mới
                                    </button>
                                </Tooltip>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <Spin tip="loading.." spinning={this.state.loading}>
                                <Table
                                    columns={[
                                        {
                                            title: "Tên",
                                            key: "name",
                                            dataIndex: "name"
                                        },
                                        {
                                            title: "Tài nguyên",
                                            key: "tags",
                                            dataIndex: "tags",
                                            render: (data) => {
                                                if (data) {
                                                    let tags = this.props.service.type_order.filter((item) => data.indexOf(item.value) > -1);
                                                    tags = tags.map((item) => item.name);
                                                    return tags.join(', ')
                                                }
                                            }
                                        },
                                        {
                                            title: "Thời gian",
                                            key: "created_time",
                                            dataIndex: "created_time",
                                            render: (data) => moment(data).format('HH:mm DD-MM-YYYY')
                                        },
                                        {
                                            title: "Hành động",
                                            key: "action",
                                            dataIndex: "_id",
                                            className: "text-right",
                                            render: (_id) => {
                                                return <div>
                                                    <Tooltip title="Chỉnh sửa">
                                                        <a href="#" className="btn btn-primary ml-5 p-5" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                visibleForm: true
                                                            }, () => {
                                                                setTimeout(() => {
                                                                    this.form.setFieldsValue(this.state.listData.find((item) => item._id == _id ));
                                                                })
                                                            })
                                                        }}>
                                                            <i class="far fa-edit"></i>
                                                        </a>
                                                    </Tooltip>
                                                    <Tooltip title="Xóa">
                                                        <a href="#" className="btn btn-danger ml-5 p-5" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.delete([_id])
                                                        }}>
                                                            <i class="far fa-trash"></i>
                                                        </a>
                                                    </Tooltip>
                                                </div>
                                            }
                                        }
                                    ]}
                                    dataSource={this.state.listData}
                                    pagination={{
                                        total: this.state.total,
                                        pageSize: this.limit,
                                        onChange: (current) => {
                                            this.page = current;
                                            this.getListData();
                                        }
                                    }}
                                />
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
