const settings = {}

export var dimens = {
    deviceWidth: window.innerWidth,
    domainSave: domainSaveCookie()
}

export const AccountMetaData = {
    token: (getCookie("auth")) ? getToken(getCookie("auth")) : '',
    logOut : () => {
        setCookie('auth', '', 0);
        window.location.href = '/login';
        return true;
    },
    logIn: () => {
        window.location.href = `/login`;
    },
    getToken: () => {
        try {
            return getCookie("auth");
        } catch {
            return '';
        }
    },
    setCookie: (name, value, days) => {
        var expires = "";
        if (days) {
        var date = new Date();
            date.setMonth(date.getMonth() + 1);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.domainSave+"; path=/";
    },
    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    checkIssetLogin: () => {
        if( !getCookie('auth')  ) {
            return false;
        }
        try{
            dimens['auth'] = getCookie("auth");
        } catch(err){
            console.log(err);
            return false;
        }
        return true;
    }
}

export const formatNumber = (nStr) => {
    nStr+='';let x=nStr.split(',');let x1=x[0];let x2=x.length>1?','+x[1]:'';let rgx=/(\d+)(\d{3})/;while(rgx.test(x1)){x1=x1.replace(rgx,'$1'+'.' + '$2');}return x1+x2;
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
        date.setMonth(date.getMonth() + 1);
        expires = "; expires=" + date.toUTCString();
    }
    // document.cookie = name + "=" + (value || "") + expires + ";domain=.muabanhay.com; path=/";
    document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.domainSave+"; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function checkIssetLogin(){
  if( !getCookie('auth')  ) {
      return false;
  }
  try{
    dimens['auth'] = atob(getCookie("auth"));
  } catch(err){
      return false
  }
  return true;
}
export function getToken(cookie){
    try {
        return atob(cookie);
    } catch {
        return '';
    }
}

export function domainSaveCookie(){
    try{
        let hostname = window.location.hostname;
        let domain = hostname;
        if(hostname.indexOf('.') > -1){
            domain = hostname.slice(hostname.indexOf('.'), hostname.length);
        } else {
            domain =  window.location.hostname;
        }
        return domain;
    } catch(err){
        return "";
    }
}

export default settings