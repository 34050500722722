import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { fetchData } from '../lib/apis';

export default class RegisterView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataForm: {},
            loading: false
        };
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true })
            let resutl = await fetchData({
                url: 'api/v1/user/register',
                method: 'post',
                data: values
            });
            this.setState({ loading: false }, () => {
                this.props.history.push('/');
                this.props.notification({
                    type: 'success',
                    title: 'Đăng ký',
                    content: 'Chúc mừng bạn đã đăng ký tài khoản thành công, giờ bạn có thể đăng nhập!'
                })
            });

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({
                    type: 'error',
                    title: 'Đăng ký',
                    content: err.message || 'Đăng ký thất bại!'
                })
            });
        }
    };
    render() {
        return (
            <div id="page-container" className="main-content-boxed">
                <main id="main-container">

                    <div className="bg-body-dark bg-pattern" >
                        <div className="row mx-0 justify-content-center">
                            <div className="hero-static col-lg-6 col-xl-4">
                                <div className="content content-full overflow-hidden">
                                    <div className="py-30 text-center">
                                        <h1 className="h4 font-w700 mt-30 mb-10">Đăng ký tài khoản mới</h1>
                                    </div>
                                    <div className="js-validation-signup">
                                        <div className="block block-themed block-rounded block-shadow">
                                            <div className="block-header bg-gd-emerald">
                                                <h3 className="block-title">Form đăng ký</h3>
                                                <div className="block-options">
                                                    <button type="button" className="btn-block-option">
                                                        <i className="si si-wrench"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="block-content">
                                                <Form
                                                    onFinish={this.onFinish}
                                                    name="basic"
                                                    className="form-login"
                                                    autoComplete="off"
                                                >
                                                    <Form.Item
                                                        name="username"
                                                        label="Tài khoản đăng nhập"
                                                        rules={[{ required: true, message: 'Không được để trống' }]}
                                                    >
                                                        <Input placeholder="vd: toolshopee"/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="fullname"
                                                        label="Họ và tên"
                                                        rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}
                                                    >
                                                        <Input placeholder="vd: john smith"/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="phone"
                                                        label="Số điện thoại"
                                                    >
                                                        <Input placeholder="vd: 0976..."/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="email"
                                                        label="Email"
                                                    >
                                                        <Input placeholder="vd: john@example.com"/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="password"
                                                        label="Mật khẩu"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng nhập mật khẩu!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input.Password placeholder="******"/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="password-confirm"
                                                        label="Nhập lại mật khẩu"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng nhập lại mật khẩu!',
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(rule, value) {
                                                                  if (!value || getFieldValue('password') === value) {
                                                                    return Promise.resolve();
                                                                  }
                                                                  return Promise.reject('Mật khẩu không trùng khớp');
                                                                },
                                                              })
                                                        ]}
                                                    >
                                                        <Input.Password placeholder="******"/>
                                                    </Form.Item>
                                                    <Form.Item
                                                            name="agreement"
                                                            valuePropName="checked"
                                                            rules={[
                                                            { validator:(_, value) => value ? Promise.resolve() : Promise.reject('Bạn phải đồng ý điều khoản của chúng tôi') },
                                                            ]}
                                                        >
                                                            <Checkbox>
                                                            Tôi đồng ý tất cả <a href="">điều khoản</a>
                                                            </Checkbox>
                                                        </Form.Item>
                                                    <Form.Item className="text-right">
                                                        <Button type="primary" htmlType="submit" loading={this.state.loading}>
                                                            Đăng ký
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                            <div className="block-content bg-body-light">
                                                <div className="form-group text-center">
                                                    {/* <a className="link-effect text-muted mr-10 mb-5 d-inline-block" href="#" data-toggle="modal" data-target="#modal-terms">
                                                        <i className="fa fa-book text-muted mr-5"></i> Điều khoản
                                                     </a> */}
                                                    <Link className="link-effect text-muted mr-10 mb-5 d-inline-block" to="/">
                                                        <i className="fa fa-user text-muted mr-5"></i> Đăng nhập
                                                     </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
        )
    }
}
