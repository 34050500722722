import React from "react";
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, Checkbox, InputNumber } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';
import { UploadFile } from '../../component';

export default class SettingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: false
        };
    }
    componentDidMount = () => {

    }

    save = async (values) => {
        try {

            this.setState({ loadingForm: true });
            if (values['banking']) values['banking'] = values['banking'].split('\n');
            values['type_order'] = this.props.service.type_order || [];
            
            
            Object.keys(values).forEach((key) => {
                if(key.indexOf('price_') > -1 && typeof values[key] == "number"){
                    let index = values['type_order'].findIndex((item) => 'price_'+item.value == key);
                    if(index > -1){
                        values['type_order'][index]['price'] = values[key];
                        delete values[key];
                    }
                }
            })

            let resutl = await this.props.fetchData({
                url: 'api/v1/service',
                method: 'post',
                data: values
            });

            this.setState({ loadingForm: false });
            this.props.notification({
                content: 'Cập nhập thành công',
                title: 'Cập nhập thiết lập',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập thiết lập',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Thiết lập</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <Form
                            initialValues={{
                                max_proxy: this.props.service.max_proxy,
                                short_code: this.props.service.short_code,
                                price_one: this.props.service.price_one,
                                banking: this.props.service.banking.join('\n'),
                                buff_phone: this.props.service.buff_phone,
                                token: this.props.service.token,
                                file_import_buff_order: this.props.service.file_import_buff_order
                            }}
                            onFinish={this.save}
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    {
                                        this.props.user.is_admin ?
                                            <Form.Item label="Token" name="token">
                                                <Input />
                                            </Form.Item>
                                            : null
                                    }
                                    {
                                        this.props.user.is_admin ?
                                            <Form.Item name="max_proxy" label="Số nick / proxy">
                                                <Input />
                                            </Form.Item> : null
                                    }
                                    <Form.Item name="file_import_buff_order" label="File excel mẫu buff order">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="short_code" label="Nội dung chuyển khoản">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="banking" label="Tài khoản nhận tiền" className="mb-0">
                                        <Input.TextArea style={{ minHeight: "100px" }} />
                                    </Form.Item>

                                    <Form.Item>
                                        <i>Lưu ý: Mỗi dòng 1 tài khoản (stk|chủ tài khoản|chi nhánh)</i>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item label="Thiết lập giá (VND)">
                                        <div className="row">
                                            {
                                                this.props.service.type_order.map((item) => {
                                                    return <Form.Item name={'price_'+item.value} label={item.name} className="mb-5 col-md-4">
                                                        <InputNumber min={0} defaultValue={item.price || 0} style={{width: "100%"}}/>
                                                    </Form.Item>
                                                })
                                            }
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>

                            <Form.Item>
                                <Button loading={this.state.loadingForm} htmlType="submit" type="primary">Lưu thiết lập</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
