import React from "react";
import { Link } from "react-router-dom";
import {
  DownloadOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import {
  Button,
  Spin,
  Pagination,
  Tooltip,
  Modal,
  Select,
  Form,
  Input,
  InputNumber,
  Upload,
  Checkbox,
  Tag,
  Table,
  Cascader,
  Alert,
} from "antd";
import qs from "qs";
import moment from "moment";
import { FormOrder } from "../component";
import { fetchData } from "../lib/apis";
import { AccountMetaData } from "../config/app";
import XLSX from "xlsx";

const { Option } = Select;

export default class BuffOrderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      total: 0,
      fileList: [],
      listDataProductCreate: [],
      total_target: 1,
      locations: [],
      dataSelected: [],
    };
    this.limit = 16;
    this.page = 1;
  }
  componentWillMount = () => {
    this.getListData();
    this.getLocation();
  };
  getListData = async (type) => {
    try {
      this.setState({ loading: true });
      if (type == "reset") this.page = 1;
      let query = {
        limit: this.limit,
        page: this.page,
        order_type: "buff_order",
      };
      if (this.formFilter) {
        query = {
          ...query,
          ...this.formFilter.getFieldsValue(),
        };
      }
      let response = await fetchData({
        url: `api/v1/order/list?${qs.stringify(query)}`,
        method: "get",
      });
      this.setState({
        listData: response.data,
        total: response.total,
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
    }
  };
  getLocation = async () => {
    try {
      let params = {
        parent_id: 0,
      };
      let response = await fetchData({
        url: `api/v1/location/get?${qs.stringify(params)}`,
        method: "get",
      });

      this.setState({
        locations: response.data.map((item) => {
          return {
            id: item.id,
            value: item.name,
            label: item.name,
            isLeaf: false,
          };
        }),
      });
    } catch (err) {}
  };
  create = async (values) => {
    try {
      this.setState({ loadingForm: true });
      let listDataProductCreate = [...this.state.listDataProductCreate];
      values["products"] = listDataProductCreate.map((item) => {
        if (item["attachments"]) {
          item["attachments"] = item["attachments"]
            .filter(
              (attachment) =>
                typeof attachment == "string" || attachment.response
            )
            .map((attachment) => {
              if (typeof attachment == "string") {
                return attachment;
              } else {
                return attachment.response.attachment.url;
              }
            });
        }
        item["order_type_time"] = values["order_type_time"] || 1;
        item["link"] = decodeURIComponent(item["link"]);

        try {
          new URL(item["link"]);
        } catch (err) {
          throw { message: "Vui lòng kiểm tra lại link sản phẩm!" };
        }

        return item;
      });
      // if (values['address']) values['address'] = values['address'].join(', ');
      if (values["address_2"] && values["address_2"].length < 5)
        throw { message: "Địa chỉ giao hàng: tối thiểu 5 ký tự!" };
      if (
        values["fullname_order"] &&
        values["fullname_order"].split(/\s/g).length < 2
      )
        throw { message: "Tên người nhận hàng: cụm từ chứa 2 từ trở lên!" };

      values["options"] = {
        address: values["address"],
        address_2: values["address_2"],
        phone_order: values["phone_order"],
        fullname_order: values["fullname_order"],
        is_free_shipping_code: values["is_free_shipping_code"],
        saveVoucher: values["saveVoucher"],
        sellerDesc: values["sellerDesc"],
        order_type_time: values["order_type_time"],
      };
      // return

      let url = `api/v1/order/create`;
      let method = "post";
      if (values["_id"]) {
        url = `api/v1/order/${values["_id"]}`;
        method = "put";
      }
      let response = await fetchData({
        url: url,
        method: method,
        data: values,
      });
      this.getListData();
      this.setState({
        loadingForm: false,
        visibleForm: false,
        error: null,
        listDataProductCreate: [],
      });
      this.form.resetFields();

      this.props.notification({
        type: "success",
        title: "Thêm order",
        content: "Thao tác thành công",
      });
      this.props.getDetailUser();
    } catch (err) {
      this.setState({
        loadingForm: false,
        error: err.message || "Đã có lỗi xảy ra",
      });
      this.props.notification({
        type: "error",
        title: "Thêm order",
        content: err.message || "Đã có lỗi xảy ra",
      });
    }
  };
  changeProduct = (value, index) => {
    let listDataProductCreate = [...this.state.listDataProductCreate];
    listDataProductCreate[index] = {
      ...listDataProductCreate[index],
      ...value,
    };
    this.setState({
      listDataProductCreate: listDataProductCreate,
    });
  };
  displayRender = (labels, selectedOptions) => {
    let address = [];
    let result = labels.map((label, i) => {
      const option = selectedOptions[i];
      address.push(label);

      return (
        <span key={option.value}>
          {label}
          {i < labels.length - 1 ? ", " : ""}
        </span>
      );
    });

    // if (this.form) this.form.setFieldsValue({ address: address.join(', ') });

    return result;
  };

  loadDataLocation = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    // load options lazily
    setTimeout(async () => {
      try {
        let res_location = await fetchData({
          url: `api/v1/location/get?parent_id=${targetOption.id}`,
          method: "get",
        });

        targetOption.children = res_location.data.map((item) => {
          return {
            id: item.id,
            value: item.name,
            label: item.name,
            isLeaf: item.level == 3 ? true : false,
          };
        });
        targetOption.isLeaf = true;
      } catch (err) {
        this.setState({
          error: err.message || "Get địa chỉ lỗi, vui lòng thử lại sau!",
        });
      }

      targetOption.loading = false;

      this.setState({
        locations: [...this.state.locations],
      });
    }, 500);
  };

  runOrder = async (ids = []) => {
    if (ids.length == 0) {
      this.props.notification({
        type: "warning",
        title: "Lỗi cập nhập",
        content: "Bạn phải chọn ít nhất 1 đơn!",
      });
      return;
    }
    let { notification } = this.props;
    let _this = this;
    Modal.confirm({
      title: "Bạn có muốn thực hiện thao tác này?",
      icon: <ExclamationCircleOutlined />,
      content: `Chạy ${ids.length} đơn`,
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            let result = await fetchData({
              url: "api/v1/order/run",
              method: "post",
              data: {
                ids: ids,
              },
            });
            notification({
              type: "success",
              title: "Cập nhập thành công",
              content: `Bạn đã cập nhập thành công ${result.data.nModified} đơn!`,
            });
            _this.getListData();
            resolve();
          } catch (err) {
            notification({
              type: "error",
              title: "Lỗi Cập nhập",
              content: err.message || "Đã cõ lỗi xảy ra",
            });
            reject();
          }
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  loadFileImport = (e) => {
    this.setState(
      {
        fileImport: e.file,
        loadingImport: true,
      },
      () => {
        let result = [];
        let total_target = 0;
        const reader = new FileReader();
        reader.onload = (evt) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

          for (let i = 1; i < data.length; i++) {
            try {
              let item_data = {
                num_target: 0,
                products: [],
                options: {
                  address: "",
                  address_2: "",
                  phone_order: "",
                  fullname_order: "",
                  order_type_time: 1,
                },
              };
              if (data[i][0] % 1 === 0) {
                // item_data["address"] = data[i][1]+', '+data[i][2]+', '+data[i][3];
                // item_data["address_2"] = data[i][4] || "";
                // item_data['phone_order'] = data[i][5].toString() || "";
                // item_data['fullname_order'] = data[i][6] || "";
                // item_data['order_type_time'] = data[i][7] == "ngoài giờ hành chính" ? 2 : 1;

                item_data["num_target"] = parseInt(data[i][8]);
                item_data["options"] = {
                  address: data[i][1] + ", " + data[i][2] + ", " + data[i][3],
                  address_2: data[i][4] || "",
                  phone_order: data[i][5].toString() || "",
                  fullname_order: data[i][6] || "",
                  order_type_time: data[i][7] == "ngoài giờ hành chính" ? 2 : 1,
                };
                item_data["products"].push({
                  link: data[i][9],
                  timestamp: Date.now(),
                  num: data[i][10],
                  text_rating: data[i][11],
                  attachments: [],
                });
                total_target += item_data["num_target"];
                result.push(item_data);
              } else {
                result[result.length - 1]["products"].push({
                  link: data[i][9],
                  timestamp: Date.now(),
                  num: data[i][10],
                  text_rating: data[i][11],
                  attachments: [],
                });
              }
            } catch (err) {
              console.log(err);
            }
          }

          result = result.filter((item) => item.num_target > 0);

          this.setState({
            loadingImport: false,
            dataImport: result,
            total_target: total_target,
          });
        };
        reader.readAsBinaryString(e.file);
      }
    );
  };
  createMany = async (values = []) => {
    try {
      this.setState({ loadingImport: true });

      if (values.length == 0) throw { message: "Không có đơn nào được thêm" };

      let response = await fetchData({
        url: `api/v1/order/create_many`,
        method: "post",
        data: { data: values, order_type: "buff_order" },
      });

      this.setState({
        loadingImport: false,
        dataImport: [],
        visibleFormImport: false,
      });

      this.getListData();

      this.props.notification({
        type: "success",
        title: "Thêm order",
        content: "Thao tác thành công",
      });
      this.props.getDetailUser();
    } catch (err) {
      this.setState({
        loadingImport: false,
        error: err.message || "Đã có lỗi xảy ra",
      });
      this.props.notification({
        type: "error",
        title: "Thêm order",
        content: err.message || "Đã có lỗi xảy ra",
      });
    }
  };
  deleteMany = async (values = []) => {
    if (values.length == 0) {
      this.props.notification({
        type: "warning",
        title: "Lỗi xóa",
        content: "Bạn phải chọn ít nhất 1 đối tượng!",
      });
      return;
    }
    let { notification } = this.props;
    let _this = this;
    Modal.confirm({
      title: "Bạn có muốn thực hiện thao tác này?",
      icon: <ExclamationCircleOutlined />,
      content: `Xóa ${values.length} đối tượng`,
      onOk() {
        return new Promise(async (resolve, reject) => {
          try {
            let result = await fetchData({
              url: "api/v1/order/delete",
              method: "delete",
              data: {
                ids: values,
              },
            });
            notification({
              type: "success",
              title: "Xóa thành công",
              content: `Bạn đã xóa thành công!`,
            });
            _this.getListData();
            resolve();
          } catch (err) {
            notification({
              type: "error",
              title: "Lỗi xóa",
              content: err.message || "Đã cõ lỗi xảy ra",
            });
            reject();
          }
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  render() {
    let order_type = this.props.service.type_order.find(
      (item) => item.value == "buff_order"
    );
    order_type = order_type || {};

    return (
      <div className="content" style={{ height: "100%", overflow: "auto" }}>
        <Modal
          title="Đơn hàng"
          visible={this.state.visibleForm}
          onCancel={() =>
            this.setState({ visibleForm: false, error: null }, () =>
              this.form.resetFields()
            )
          }
          footer={null}
          width={1000}
        >
          <Form
            onFinish={this.create}
            ref={(evt) => (this.form = evt)}
            initialValues={{
              order_type: "buff_order",
              num_product: 1,
              order_type_time: 1,
              num_target: 1,
              is_shipping_code: false,
            }}
          >
            {this.state.error ? (
              <Alert
                style={{ marginBottom: "20px" }}
                type="error"
                message={this.state.error}
              />
            ) : null}

            <Form.Item name="order_type" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="_id" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="address" noStyle>
              <Input type="hidden" />
            </Form.Item>

            {!this.state.detailOrder ? (
              <div
                className="block-header-default p-3"
                style={{ marginBottom: "10px" }}
              >
                <p className="mb-2">
                  Tổng số lượng: <b>{this.state.total_target}</b>
                </p>
                <p className="mb-2">
                  Tổng tiền: số lượng * {order_type.price || 0} ={" "}
                  <b>
                    {this.props.formatPrice(
                      this.state.total_target * order_type.price || 0
                    )}
                  </b>
                </p>
              </div>
            ) : null}

            <div className="row">
              <Form.Item
                name="num_target"
                label="Số lượt buff"
                className="col-md-6"
              >
                <InputNumber
                  min={1}
                  onChange={(e) => this.setState({ total_target: e })}
                  style={{ width: "100%" }}
                  disabled={this.state.detailOrder ? true : false}
                />
              </Form.Item>
              <Form.Item
                name="order_type_time"
                label="Thời gian vận chuyển"
                className="col-md-6"
              >
                <Select>
                  <Select.Option value={1}>Trong giờ hành chính</Select.Option>
                  <Select.Option value={2}>Ngoài giờ hành chính</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="row">
              <Form.Item
                label="Địa chỉ giao hàng"
                className="col-md-6"
                name="arr_address"
              >
                <Cascader
                  options={this.state.locations}
                  displayRender={this.displayRender}
                  style={{ width: "100%" }}
                  placeHolder="Tỉnh/ Thành phố, Quận/ Huyện, Phường/ Xã"
                  allowClear={true}
                  loadData={this.loadDataLocation}
                  showSearch={{
                    filter: (inputValue, path) => {
                      return path.some(
                        (option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                      );
                    },
                  }}
                  onChange={(value) => {
                    this.form.setFieldsValue({
                      address: value.join(", "),
                    });
                  }}
                />
                {this.state.detailOrder && this.state.detailOrder.address ? (
                  <div
                    className="block-header-default p-1"
                    style={{ marginTop: "10px" }}
                  >
                    {this.state.detailOrder.address}
                  </div>
                ) : null}
              </Form.Item>
              <Form.Item
                name="address_2"
                label="Địa chỉ giao hàng cụ thể"
                className="col-md-6"
              >
                <Input placeHolder="Địa chỉ cụ thể" />
              </Form.Item>

              <Form.Item
                name="phone_order"
                label="SDT nhận hàng"
                className="col-md-6"
              >
                <Input placeHolder="" />
              </Form.Item>
              <Form.Item
                name="fullname_order"
                label="Tên người nhận hàng"
                className="col-md-6"
              >
                <Input placeHolder="" />
              </Form.Item>
              <Form.Item
                name="sellerDesc"
                label="Lưu ý cho người bán"
                className="col-md-12"
              >
                <Input.TextArea rows={2} placeHolder="" />
              </Form.Item>
              <Form.Item
                name="is_free_shipping_code"
                className="col-md-6"
                valuePropName="checked"
              >
                <Checkbox>Áp mã miễn phí vận chuyển</Checkbox>
              </Form.Item>
              <Form.Item
                name="saveVoucher"
                className="col-md-6"
                valuePropName="checked"
              >
                <Checkbox>Tìm và lưu mã shopee freeship</Checkbox>
              </Form.Item>
            </div>

            <Form.Item label="Sản phẩm">
              {!this.state.detailOrder ||
              this.state.detailOrder.status == -1 ? (
                <div className="text-right" style={{ marginBottom: "10px" }}>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      let listDataProductCreate = [
                        ...this.state.listDataProductCreate,
                      ];
                      listDataProductCreate.unshift({
                        timestamp: Date.now(),
                        link: "",
                        text_rating: "",
                        attachments: [],
                        num: 1,
                      });
                      console.log(listDataProductCreate);
                      this.setState({
                        listDataProductCreate: JSON.parse(
                          JSON.stringify(listDataProductCreate)
                        ),
                      });
                    }}
                  >
                    Thêm sản phẩm
                  </Button>
                </div>
              ) : null}
              <div className="table-responsive">
                <Table
                  dataSource={this.state.listDataProductCreate}
                  columns={[
                    {
                      title: "#",
                      render: (item, value, index) => {
                        return this.page * this.limit - this.limit + index + 1;
                      },
                    },
                    {
                      title: "Link sản phẩm",
                      render: (item, value, index) => {
                        return (
                          <Input
                            value={item.link}
                            onChange={(e) => {
                              this.changeProduct(
                                {
                                  link: e.target.value,
                                },
                                index
                              );
                            }}
                          />
                        );
                      },
                    },
                    {
                      title: "Số lượng",
                      render: (item, value, index) => {
                        return (
                          <InputNumber
                            value={item.num}
                            onChange={(e) => {
                              this.changeProduct(
                                {
                                  num: e,
                                },
                                index
                              );
                            }}
                            min={1}
                          />
                        );
                      },
                    },
                    {
                      title: "Text review",
                      render: (item, value, index) => {
                        return (
                          <Input.TextArea
                            value={item.text_rating}
                            onChange={(e) => {
                              this.changeProduct(
                                {
                                  text_rating: e.target.value,
                                },
                                index
                              );
                            }}
                            style={{ height: "50px" }}
                          />
                        );
                      },
                    },
                    {
                      title: "File review",
                      render: (item, value, index) => {
                        let attachments = item.attachments
                          ? item.attachments.map((item, i) => {
                              if (typeof item == "string") {
                                item = {
                                  uid: `${i}`,
                                  status: "done",
                                  response: {
                                    attachment: {
                                      url: item,
                                    },
                                  },
                                  url: item,
                                  name: item,
                                };
                              }
                              return item;
                            })
                          : [];
                        return (
                          <div style={{ maxWidth: "200px" }}>
                            {!this.state.detailOrder ||
                            this.state.detailOrder.status == -1 ? (
                              <Upload
                                action={`${process.env.REACT_APP_API_URL}/api/v1/uploads`}
                                withCredentials={true}
                                headers={{
                                  Authorization: AccountMetaData.getToken(),
                                }}
                                ref={(evt) => (this.uploadFile = evt)}
                                accept={"image/*"}
                                onChange={({ fileList }) => {
                                  this.changeProduct(
                                    {
                                      attachments: fileList,
                                    },
                                    index
                                  );
                                }}
                                // maxCount={1}
                                fileList={attachments}
                                key={index}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Click to Upload
                                </Button>
                              </Upload>
                            ) : (
                              item.attachments.map((attachment, i) => {
                                return (
                                  <span>
                                    <a href={attachment} target="_blank">
                                      File {i + 1}
                                    </a>
                                    {i < item.attachments.length - 1
                                      ? ", "
                                      : ""}
                                  </span>
                                );
                              })
                            )}
                          </div>
                        );
                      },
                    },
                    {
                      title: "Hành động",
                      className: "text-right",
                      render: (item, value, index) => {
                        return (
                          <Tooltip title="Xóa">
                            <Button
                              size="small"
                              onClick={() => {
                                this.state.listDataProductCreate.splice(
                                  index,
                                  1
                                );
                                this.setState({
                                  listDataProductCreate: JSON.parse(
                                    JSON.stringify(
                                      this.state.listDataProductCreate
                                    )
                                  ),
                                });
                              }}
                              disabled={
                                this.state.detailOrder &&
                                this.state.detailOrder.status != -1
                                  ? true
                                  : false
                              }
                            >
                              <i className="far fa-trash"></i>
                            </Button>
                          </Tooltip>
                        );
                      },
                    },
                  ]}
                  pagination={false}
                  // rowKey="timestamp"
                  // scroll={{ y: 200 }}
                />
              </div>
            </Form.Item>

            <Form.Item name="note" label="Ghi chú">
              <Input.TextArea style={{ minHeight: "100px" }} />
            </Form.Item>

            {/* <div style={{ marginBottom: "10px" }} className="block-header-default p-3">Ghi chú:
                            <p className="mb-2">- Cấu trúc thông tin nhận hàng: Số điện thoại | Tên | Địa chỉ</p>
                            <p className="mb-2">- Thông tin nhận hàng: mỗi dòng 1 thông tin, hệ thống tự random</p>
                            <p className="mb-2">- Nhắn tin với shop: mỗi dòng 1 tin nhắn, hệ thống tự random.</p>
                        </div> */}

            <div className="text-right">
              {!this.state.detailOrder ? (
                <Button
                  type="primary"
                  loading={this.state.loadingForm}
                  htmlType="submit"
                >
                  Tiếp tục
                </Button>
              ) : (
                <div>
                  {this.state.detailOrder.status == -1 ? (
                    <Button
                      className="mr-2"
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loadingForm}
                    >
                      Lưu
                    </Button>
                  ) : null}
                  <Button
                    type="default"
                    htmlType="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ visibleForm: false });
                    }}
                  >
                    Đóng
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </Modal>
        <Modal
          title="Đơn hàng"
          visible={this.state.visibleFormImport}
          onCancel={() =>
            this.setState({ visibleFormImport: false, error: null })
          }
          footer={null}
        >
          <Form>
            {this.state.error ? (
              <Alert
                style={{ marginBottom: "20px" }}
                type="error"
                message={this.state.error}
              />
            ) : null}
            <div
              className="block-header-default p-3"
              style={{ marginBottom: "10px" }}
            >
              <p className="mb-2">
                Số đơn:{" "}
                <b>
                  {this.state.dataImport ? this.state.dataImport.length : 0}
                </b>
              </p>
              <p className="mb-2">
                Số lượng buff: <b>{this.state.total_target}</b>
              </p>
              <p className="mb-2">
                Tổng tiền: số lượng * {order_type.price || 0} ={" "}
                <b>
                  {this.props.formatPrice(
                    this.state.total_target * order_type.price || 0
                  )}
                </b>
              </p>
            </div>
            <Form.Item name="upload">
              <Upload.Dragger
                beforeUpload={() => false}
                accept={".xlsx"}
                onChange={this.loadFileImport}
                showUploadList={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  {this.state.fileImport
                    ? this.state.fileImport.name
                    : "Support for a single or bulk upload."}
                </p>
              </Upload.Dragger>
            </Form.Item>
            {this.props.service.file_import_buff_order ? (
              <div>
                <a
                  href={this.props.service.file_import_buff_order}
                  target="_blank"
                >
                  <i className="fa fa-download"></i> Tải file mẫu
                </a>
              </div>
            ) : null}
            <div className="text-right">
              <Button
                type="primary"
                loading={this.state.loadingImport}
                htmlType="button"
                onClick={() => this.createMany(this.state.dataImport || [])}
              >
                Tiếp tục
              </Button>
            </div>
          </Form>
        </Modal>
        <div className="block block-rounded">
          <div className="block-header pb-10 justify-content-between align-items-center">
            <div>
              <h3 className="block-title block-title-m ">Đơn hàng</h3>
            </div>
          </div>
          <div className="block-content">
            <div className="d-sm-flex align-items-center justify-content-between mb-10">
              <div className="d-flex mb-10">
                <button className={`btn btn-primary btn-sm d-flex`}>
                  Tất cả: {this.state.total}
                </button>
                <Tooltip title="Làm mới">
                  <button
                    type="button"
                    className={`btn btn-primary btn-sm d-flex align-items-center ml-5`}
                    onClick={() => this.getListData()}
                  >
                    <i className="fa fa-refresh"></i>
                  </button>
                </Tooltip>
                <Tooltip title="Thêm mới">
                  <button
                    type="button"
                    className={`btn btn-primary btn-sm d-flex align-items-center ml-5`}
                    onClick={() =>
                      this.setState({
                        visibleForm: true,
                        listDataProductCreate: [],
                        total_target: 1,
                        detailOrder: false,
                      })
                    }
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </Tooltip>
                <Tooltip title="Nhập file">
                  <button
                    type="button"
                    className={`btn btn-primary btn-sm d-flex align-items-center ml-5`}
                    onClick={() =>
                      this.setState({
                        visibleFormImport: true,
                        total_target: 0,
                        dataImport: [],
                        fileImport: null,
                      })
                    }
                  >
                    <i className="fa fa-upload"></i>
                  </button>
                </Tooltip>
                <Tooltip title="Xóa">
                  <button
                    type="button"
                    className={`btn btn-danger btn-sm d-flex align-items-center ml-5`}
                    onClick={() => this.deleteMany(this.state.dataSelected)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </Tooltip>
                <Tooltip>
                  <button
                    type="button"
                    className={`btn btn-primary btn-sm d-flex align-items-center ml-5`}
                    onClick={() => this.runOrder(this.state.dataSelected)}
                  >
                    Chạy
                  </button>
                </Tooltip>
              </div>
            </div>

            <Form
              initialValues={{
                status: "",
                order_type: "",
              }}
              onChange={(e) => {
                if (e.target.nodeName == "INPUT") {
                  if (this.timeout) clearTimeout(this.timeout);
                  this.timeout = setTimeout(() => {
                    this.getListData("reset");
                  }, 300);
                } else {
                  this.getListData();
                }
              }}
              ref={(evt) => (this.formFilter = evt)}
            >
              <div className="row tool-filter">
                <div className="col-md-2 col-lg-2 pr-5">
                  <Form.Item name="status">
                    <Select
                      onChange={(e) => {
                        this.getListData("reset");
                      }}
                    >
                      <Option value="">Trạng thái</Option>
                      <Option value={-1}>Chờ xử lý</Option>
                      <Option value={0}>Đang xử lý</Option>
                      <Option value={1}>Thành công</Option>
                      <Option value={2}>Thất bại</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                  <Tooltip title="Xóa lọc">
                    <Button
                      className="d-flex align-items-center justify-content-center"
                      icon={<DeleteOutlined />}
                      type="primary"
                      size={"middle"}
                      onClick={() => {
                        this.formFilter.resetFields();
                        this.getListData("reset");
                      }}
                    ></Button>
                  </Tooltip>
                </div>
              </div>
            </Form>

            <div className="table-responsive">
              <Table
                dataSource={this.state.listData}
                columns={[
                  {
                    title: "Id",
                    render: (item, value, index) => {
                      return "#" + item.order_id;
                    },
                  },
                  {
                    title: "Tổng tiền",
                    render: (item) => {
                      return this.props.formatPrice(item.total_price);
                    },
                  },
                  {
                    title: "Số lượng",
                    dataIndex: "num_target",
                  },
                  {
                    title: "Sản phẩm",
                    dataIndex: "num_product",
                  },
                  {
                    title: "Thành công",
                    dataIndex: "num_success",
                  },
                  // {
                  //     title: "Thất bại",
                  //     dataIndex: "num_failed"
                  // },
                  {
                    title: "Ghi chú",
                    dataIndex: "note",
                    render: (item) => (
                      <Input.TextArea value={item}></Input.TextArea>
                    ),
                  },
                  {
                    title: "Trạng thái",
                    render: (item) => {
                      return (
                        <Tag
                          color={
                            item.status == 0 || item.status == -1
                              ? "processing"
                              : item.status == 1
                              ? "success"
                              : "error"
                          }
                        >
                          {item.status == -1
                            ? "Chờ xử lý"
                            : item.status == 0
                            ? "Đang xử lý"
                            : item.status == 1
                            ? "Thành công"
                            : item.status == 2
                            ? "Thất bại"
                            : "Không xác định"}
                        </Tag>
                      );
                    },
                  },
                  {
                    title: "Ngày",
                    render: (item) => {
                      return moment(item.created_time).format(
                        "YYYY/MM/DD HH:mm"
                      );
                    },
                  },
                  {
                    title: "Hành động",
                    className: "text-right",
                    render: (item) => {
                      return (
                        <div>
                          <Tooltip title="Chi tiết">
                            <Button
                              size="small"
                              onClick={() => {
                                this.setState(
                                  {
                                    detailOrder: JSON.parse(
                                      JSON.stringify(item)
                                    ),
                                    visibleForm: true,
                                    listDataProductCreate: JSON.parse(
                                      JSON.stringify(item.products)
                                    ),
                                  },
                                  () => {
                                    setTimeout(() => {
                                      if (this.form) {
                                        this.form.setFieldsValue(item);
                                        if (item.options)
                                          this.form.setFieldsValue(
                                            item.options
                                          );
                                      }
                                    });
                                  }
                                );
                              }}
                              className="mr-5"
                            >
                              {item.status == -1 ? (
                                <i className="far fa-edit"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Xem đơn đã đặt">
                            <Button
                              size="small"
                              onClick={() => {
                                this.props.history.push(
                                  `/shop_order?order_id=${item.order_id}`
                                );
                              }}
                              className="mr-5"
                            >
                              <i className="far fa-shopping-cart"></i>
                            </Button>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                ]}
                dataSource={this.state.listData}
                pagination={{
                  total: this.state.total,
                  pageSize: this.limit,
                  onChange: (current) => {
                    this.page = current;
                    this.getListData();
                  },
                  onShowSizeChange: (current, size) => {
                    this.page = current;
                    this.limit = size;
                    this.getListData();
                  },
                  current: this.page,
                  showSizeChanger: true,
                }}
                rowKey="_id"
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys: this.state.dataSelected,
                  onChange: (values) => {
                    this.setState({
                      dataSelected: values,
                    });
                  },
                  getCheckboxProps: (record) => ({
                    disabled: record.status == 1 || record.status == 2,
                  }),
                }}
                loading={this.state.loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
