import React from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount = () => {
        document.querySelectorAll('#sidebar .nav-main li').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                document.querySelector('#page-container').classList.remove('sidebar-o-xs');
            })
        })
        document.querySelectorAll('[data-toggle="sidebar"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                let action = this.getAttribute('data-action');
                if (action == 'sidebar_hide') {
                    document.querySelector('#page-container').classList.remove('sidebar-o');
                    document.querySelector('#page-container').classList.remove('sidebar-o-xs');
                }
                if (action == 'sidebar_toggle') {
                    let page_container = document.querySelector('#page-container');
                    if (window.innerWidth > 992) {
                        if (page_container.classList.contains('sidebar-o')) {
                            page_container.classList.remove('sidebar-o');
                        } else {
                            page_container.classList.add('sidebar-o');
                        }
                    } else {
                        if (page_container.classList.contains('sidebar-o-xs')) {
                            page_container.classList.remove('sidebar-o-xs');
                        } else {
                            page_container.classList.add('sidebar-o-xs');
                        }
                    }

                }
            })
        })
        document.querySelectorAll('.nav-submenu[data-toggle="nav-submenu"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                let list_class = e.target.parentNode.getAttribute('class');
                if (list_class.indexOf('open') > -1) {
                    e.target.parentNode.classList.remove('open');
                } else {
                    e.target.parentNode.classList.add('open');
                }
            })
        })
    }
    render() {
        let pathname = window.location.pathname.replace(/\/$/g, '');
        let menu_admin = [
            {
                name: "Quản trị"
            },
            {
                name: "Thống kê",
                link: "/admin/statitiscal"
            },
            {
                name: "Giao dịch",
                link: "/admin/transaction"
            },
            {
                name: "Người dùng",
                link: "/admin/users"
            },
            {
                name: "Client",
                link: "/admin/client",
                is_admin: true
            },
            {
                name: "Thiết lập",
                link: "/admin/setting"
            },
            {
                name: "Tài Nguyên",
                is_admin: true
            },
            {
                name: "Folder",
                link: "/admin/nick/folder",
                is_admin: true
            },
            {
                name: "Nick shopee",
                link: "/admin/nick",
                is_admin: true
            },
            {
                name: "Proxy",
                link: "/admin/proxy",
                is_admin: true
            }
        ]

        return (
            <nav id="sidebar">
                <div className="sidebar-content">
                    <div className="content-header justify-content-start">
                        <Link className="img-link mr-15" to="/me">
                            <img className="img-avatar" src={this.props.user.avatar} alt="" style={{ width: "35px", height: "35px" }}
                                onError={(e) => e.target.src = "/user.jpg"}
                            />
                        </Link>
                        <div>
                            <p className="m-0">Số dư: {this.props.formatPrice(this.props.user.balance)}</p>
                            <p className="m-0">Tham gia: {moment(this.props.user.created_time).format('DD-MM-YYYY')}</p>
                        </div>
                        <button type="button" className="btn btn-circle btn-dual-secondary align-v-r d-lg-none btn-hide-sidebar" data-toggle="sidebar" data-action="sidebar_hide">
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                    <div className="content-side content-side-full">
                        <ul className="nav-main">
                            <li className="pt-10 nav-main-heading border-t">
                                <span>Hỗ trợ</span>
                            </li>
                            <li>
                                <a href={this.props.service.link_guides} target="_blank">
                                    <span>Hướng dẫn sử dụng</span>
                                </a>
                            </li>
                            <li className="pt-10 nav-main-heading border-t">
                                <span>Quản lý</span>
                            </li>
                            <li>
                                <Link to="/" className={pathname == '' ? "active" : ""}>
                                    <span>Đơn hàng</span>
                                </Link>
                            </li>
                            {
                                this.props.service.type_order.find((item) => item.value == "buff_order") ?
                                    <li>
                                        <Link to="/buff_order" className={pathname == '/buff_order' ? "active" : ""}>
                                            <span>Buff đơn hàng</span>
                                        </Link>
                                    </li> : null
                            }
                            <li className="pt-10 nav-main-heading border-t">
                                <span>Cá nhân</span>
                            </li>
                            <li>
                                <Link to="/me" className={pathname == '/me' ? "active" : ""}>
                                    <span>Thông tin</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/package" className={pathname == '/package' ? "active" : ""}>
                                    <span>Giao dịch - Gói cước</span>
                                </Link>
                            </li>
                            {
                                this.props.user.is_admin || this.props.user.is_manage ?
                                    menu_admin.map((item, i) => {
                                        let menu = item.link ?
                                            <li key={i}>
                                                <Link to={item.link} className={pathname == item.link ? "active" : ""}>
                                                    <span>{item.name}</span>
                                                </Link>
                                            </li>
                                            : <li className="pt-10 nav-main-heading border-t" key={i}>
                                                <span>{item.name}</span>
                                            </li>;
                                        if (item.is_admin && !this.props.user.is_admin) {
                                            menu = null;
                                        }
                                        return menu
                                    }) : null
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
