import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Modal, Form, Input, Button} from 'antd';
import { AccountMetaData, setCookie} from '../config/app';
import moment from 'moment';

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }
    componentDidMount = () => {
        
    }
    onSave = async (values) => {
        try {
            this.setState({ loading: true });
            
            let resutl = await this.props.fetchData({
                url: 'api/v1/user/change_pw',
                method: 'post',
                data: values
            });
            
            setCookie('auth', resutl['token'], 7);

            this.setState({ loading: false, visible: false }, () => {
                this.form.resetFields();
                this.props.notification({
                    type: 'success',
                    title: 'Đổi mật khẩu',
                    content: 'Thao tác thành công'
                })
            });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false }, () => {
                this.props.message({ 
                    content: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    key: 'error'
                })
            });
        }
    }
    render() {
        return (
            <Modal
                    title="Đổi mật khẩu"
                    visible={this.props.visible}
                    onCancel={this.props.hide}
                    footer={null}
                >
                    <Form
                        onFinish={this.onSave}
                        ref={(evt) => this.form = evt}
                    >
                        <Form.Item
                            name="password_old"
                            rules={[{ required: true, message: 'Bạn phải nhập mật khẩu cũ!' }]}
                        >
                            <Input.Password placeholder="Mật khẩu cũ" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Bạn phải nhập mật khẩu mới!' }]}
                        >
                            <Input.Password placeholder="Mật khẩu mới" />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Không trùng khớp');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Nhập lại mật khẩu" />
                        </Form.Item>
                        <div className="text-right">
                            <Button type="primary" loading={this.state.loading} htmlType="submit">
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
                </Modal>
        )
    }
}
