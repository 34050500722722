import React, { Component } from 'react';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, Radio, InputNumber, Select} from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled } from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { fetchData } from '../lib/apis';
import { formatPrice } from '../lib/helpers'

export default class upgradePackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_price_upgrade: 1000 * this.props.service.price_one,
            loading: false
        }
        this.form = null;
    }
    componentWillUpdate = (nextProps, nextState) => {
        try{
            if (nextProps.visible && nextProps.visible != this.props.visible) {
                if(this.form){
                    this.setState({
                        total_price_upgrade: 0,
                        package_upgrade: null
                    }, () => {
                        this.form.resetFields();
                    })
                }
            }
        } catch(err){

        }
    }
    changeUpgrade = () => {
        let upgrade_num = parseInt(this.form.getFieldValue("upgrade_num")) || 0;
        this.setState({
            total_price_upgrade: upgrade_num * this.props.service.price_one
        })
    }
    upgrade = async (values) => {
        try{
            this.setState({loading : true })
            let res = await fetchData({
                url: `api/v1/transaction/create`,
                method: 'post',
                data: {
                    ...values,
                    type_package: 2
                }
            });
            this.setState({loading : false }, () => {
                this.props.hideUpgrade();
                this.props.showDetailPayment(true, res.data, null, true);
            })
        } catch(err){
            this.setState({loading : false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Đăng ký gói cước',
                type: 'error'
            })
        }
    }
    render() {
        return (
            <Modal
                title="Thông tin gói cước"
                visible={this.props.visible}
                onCancel={this.props.hideUpgrade}
                footer={null}
            >
                <div>
                    <div className="export-option text-left pt-15">
                        <h5 className="title m-0">Bạn đang sử dụng gói {this.props.user.package.name}</h5>
                        <div className="p-10 pl-20">
                            <p className="m-0 mb-5">
                                {
                                    this.props.user.package.max == -1 ? '- Không giới hạn số lượt buff' :
                                    `- Bạn còn ${formatPrice(this.props.user.package.num_view)} lượt quét buff`
                                }
                            </p>
                            <p className="m-0">
                                - Ngày hết hạn: { moment(this.props.user.package.end_time).format('DD-MM-YYYY') }
                            </p>
                        </div>
                    </div>
                    <div className="export-container" style={{ minHeight: "unset" }}>
                        {
                            this.props.user.package.is_default == 1 ?
                                <div className="text-center">
                                    <Button className="btn btn-rounded btn-noborder btn-warning" onClick={() => {
                                        this.props.hideUpgrade();
                                        this.props.history.push('/package');
                                    }} >
                                        Nâng cấp gói cước
                                </Button>
                                </div> :
                                <div className="text-center">
                                    <Form
                                        initialValues={{
                                            upgrade_num: 1000,
                                        }}
                                        onChange={this.changeUpgrade}
                                        ref={(evt) => this.form = evt}
                                        onFinish={this.upgrade}
                                    >
                                        <Form.Item name="upgrade_num" label={`Lượt buff (${this.props.service.price_one}đ / 1 buff)`} className="mb-10"
                                            
                                        >
                                            <InputNumber style={{width: "100%"}} />
                                        </Form.Item>
                                        <div className="text-left">
                                            <b>Tổng tiền: <NumberFormat value={this.state.total_price_upgrade} displayType={'text'} thousandSeparator={true} /> đ</b>
                                        </div>
                                        <Button htmlType="submit" className="btn btn-rounded btn-noborder btn-primary d-flex m-auto" loading={this.state.loading}

                                        >
                                            Mua thêm
                                        </Button>
                                    </Form>
                                </div>
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}
