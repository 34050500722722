import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { message, Spin, notification, Modal } from 'antd'
import { fetchData, requestApiFb} from './lib/apis';
import { setCookie, AccountMetaData } from './config/app';
import { formatPrice } from './lib/helpers'

import { MainlayoutView, LoginView, RegisterView } from './views';

class AppComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }
  componentDidMount = () => {

  }

  notification = (options) => {
    let { type, title, content, key, duration } = options;
    notification[type]({
      message: title,
      description: content,
      key: key,
      duration: duration ? duration : 5
    })
  }
  message = (options) => {
    let { type, key, content, duration } = options;
    message[type]({
      content: content,
      key: key,
      duration: duration
    })
  }
  getDetailUser = async () => {
    try {
      let response = await fetchData({
        url: 'api/v1/user/detail',
        method: 'get'
      });
      this.setState({
        user: response.data,
        service: response.service
      })
    } catch (err) {
      this.notification({
        type: "error",
        title: "Đăng nhập",
        content: err.message || "Đăng nhập thất bại"
      })
      AccountMetaData.logOut();
    }
  }

  render() {
    let prop_m = {
      notification: this.notification,
      message: this.message,
      user: this.state.user,
      updateUser: (user) => this.setState({ user: user}),
      service: this.state.service,
      getDetailUser: this.getDetailUser,
      fetchData: fetchData,
      formatPrice: formatPrice,
      AccountMetaData: AccountMetaData
    }
    return (
      <Router>
        <Switch>
          <Route
            exact
            path='/login'
            render={props => <LoginView {...props} {...prop_m}/>}
          />
          <Route
            exact
            path='/register'
            render={props => <RegisterView {...props} {...prop_m}/>}
          />
          <Route
            path='/'
            render={props => <MainlayoutView {...props} {...prop_m}
            />}
          />

        </Switch>
      </Router>
    )
  }
}
export default AppComponent;
