import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Select, Form, Input, InputNumber, Upload, Checkbox, Table, Tag } from 'antd';
import qs from 'qs';
import moment from 'moment';
import { FormOrder } from '../component';
import { fetchData } from '../lib/apis';
import { AccountMetaData } from '../config/app'

const { Option } = Select;

export default class HomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            order_type: "traffic",
            fileList: [],
            listTypeOrder: [],
            buff_phone: false,
            total_target: 1
        };
        this.limit = 16;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == "reset") this.page = 1;
            let query = {
                limit: this.limit,
                page: this.page,
            }
            if (this.formFilter) {
                query = {
                    ...query,
                    ...this.formFilter.getFieldsValue()
                }
            }
            let response = await fetchData({
                url: `api/v1/order/list?${qs.stringify(query)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false,
                listTypeOrder: response.type || []
            })
        } catch (err) {
            this.setState({ loading: false })
        }
    }
    create = async (values) => {
        try {
            this.setState({ loadingForm: true });

            values['options'] = {};

            if (values['keyword']) values['options']['keyword'] = values['keyword'];
            if (values['shop_id']) values['options']['shop_id'] = values['shop_id'];
            if (values['time_x']) values['options']['time_x'] = values['time_x'];
            if (values['time_y']) values['options']['time_y'] = values['time_y'];
            if (values['time_z']) values['options']['time_z'] = values['time_z'];
            if (values['actions']) {
                values['actions']['actions'] = values['actions'];
                values['actions'].forEach((item) => {
                    values['options'][item] = 1;
                })
            }
            if (values['time_view_product']) values['options']['time_view_product'] = values['time_view_product'];

            if (values['product_link']) {
                values['products'] = [
                    {
                        link: values['product_link'],
                        timestamp: Date.now()
                    }
                ]
                values['options']['product_link'] = values['product_link'];
            }

            let response = await fetchData({
                url: `api/v1/order/create`,
                method: 'post',
                data: values
            })
            this.getListData();

            this.setState({ loadingForm: false, visibleForm: false });
            this.form.resetFields();

            this.props.notification({
                type: "success",
                title: "Thêm order",
                content: "Thao tác thành công"
            })
            this.props.getDetailUser();

        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                type: "error",
                title: "Thêm order",
                content: err.message || "Đã có lỗi xảy ra"
            })
        }
    }
    deleteMany = async (values = []) => {
        if (values.length == 0) {
            this.props.notification({
                type: 'warning',
                title: 'Lỗi xóa',
                content: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${values.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v1/order/delete',
                            method: 'delete',
                            data: {
                                ids: values
                            },
                        });
                        notification({
                            type: 'success',
                            title: 'Xóa thành công',
                            content: `Bạn đã xóa thành công!`
                        })
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            title: 'Lỗi xóa',
                            content: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    render() {
        let { listData } = this.state;
        let order_type = this.props.service.type_order.find((item) => item.value == this.state.order_type);
        order_type = order_type || {};

        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <Modal
                    title="Thêm đơn hàng"
                    visible={this.state.visibleForm}
                    onCancel={() => this.setState({ visibleForm: false }, () => this.form.resetFields())}
                    footer={null}
                    width={800}
                    centered
                >
                    <Form
                        onFinish={this.create}
                        ref={(evt) => this.form = evt}
                        initialValues={{
                            num_target: 1,
                            order_type: this.state.order_type,
                            buff_phone: true,
                            time_view_product: 60
                        }}
                    >
                        <div className="block-header-default p-3" style={{ marginBottom: "10px" }}>
                            <p className="mb-2">Tổng tiền: số lượng * {order_type.price || 0} = <b>{this.props.formatPrice(this.state.total_target * order_type.price || 0)}</b></p>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item name="buff_phone" label="Thiết bị buff">
                                    <Select>
                                        <Select.Option value={false} >PC</Select.Option>
                                        <Select.Option value={true} >Phone</Select.Option>
                                    </Select>
                                </Form.Item>
                                <div className="row">
                                    <Form.Item
                                        name="order_type"
                                        label="Loại"
                                        className="col-md-6"
                                    >
                                        <Select onChange={(e) => this.setState({ order_type: e })}>
                                            {
                                                this.props.service.type_order ? this.props.service.type_order.map((item, i) => {
                                                    return item.value != "buff_order" ? <Select.Option key={i} value={item.value} >{item.name}</Select.Option> : null
                                                }) : null
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="num_target"
                                        rules={[{ required: true, message: 'không được để trống!' }]}
                                        label="Số lượng"
                                        className="col-md-6"
                                    >
                                        <InputNumber style={{ width: "100%" }} min={1} onChange={(e) => this.setState({ total_target: e })} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {
                                    this.state.order_type == "traffic" ?
                                        <div>
                                            <Form.Item
                                                name="keyword"
                                                label="Từ khóa"
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="product_link"
                                                label="Link Sản phẩm"
                                                rules={[{ required: true, message: 'không được để trống!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Hành động" name="actions">
                                                <Select
                                                    mode="tags"
                                                    placeholder="Chọn hành động"
                                                    style={{ width: '100%' }}
                                                    defaultValue={["like_product"]}
                                                >
                                                    {
                                                        [
                                                            {
                                                                name: "Like sản phẩm",
                                                                value: "like_product"
                                                            },
                                                            {
                                                                name: "Xem sản phẩm của shop",
                                                                value: "view_all_products"
                                                            },
                                                            {
                                                                name: "Xem chi tiết sản phẩm",
                                                                value: "view_product_detail"
                                                            },
                                                            {
                                                                name: "Like hữu ích review",
                                                                value: "like_review"
                                                            }
                                                        ].map((item, i) => {
                                                            return <Select.Option key={i} value={item.value}>{item.name}</Select.Option>
                                                        })
                                                    }
                                                </Select> 
                                            </Form.Item>
                                            <Form.Item
                                                name="time_view_product"
                                                label="Thời gian lướt (giây)"
                                                rules={[{ required: true, message: 'không được để trống!' }]}
                                                
                                            >
                                                <InputNumber />
                                            </Form.Item>
                                        </div> : this.state.order_type == "click_ads" ?
                                            <div>
                                                <Form.Item
                                                    name="keyword"
                                                    label="Từ khóa"
                                                    rules={[{ required: true, message: 'không được để trống!' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    name="product_link"
                                                    label="Link Sản phẩm"
                                                    rules={[{ required: true, message: 'không được để trống!' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div> : this.state.order_type == "like_shop" ?
                                                <div>
                                                    <Form.Item
                                                        name="shop_id"
                                                        label="Shop id"
                                                        rules={[{ required: true, message: 'không được để trống!' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </div> : this.state.order_type == "follow_shop" ?
                                                    <div>
                                                        <Form.Item
                                                            name="shop_id"
                                                            label="Shop id"
                                                            rules={[{ required: true, message: 'không được để trống!' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </div> : null
                                }
                                <Form.Item name="note" label="Ghi chú">
                                    <Input.TextArea style={{minHeight: "100px"}}/>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="text-right">
                            <Button type="primary" loading={this.state.loadingForm} htmlType="submit">
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Đơn hàng</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Xóa">
                                    <button type="button" className={`btn btn-danger btn-sm d-flex align-items-center ml-5`} onClick={() => this.deleteMany(this.state.dataSelected)}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Thêm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.setState({ visibleForm: true, total_target: 1 })}>
                                        Thêm mới
                                    </button>
                                </Tooltip>
                            </div>
                            <div className={`${this.props.loading ? 'data-loading' : ''}`}>
                                <Pagination
                                    total={this.state.total}
                                    defaultCurrent={1}
                                    showSizeChanger={false}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getListData();
                                    }}
                                    simple
                                />
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: "",
                                order_type: ""
                            }}
                            onChange={(e) => {
                                if (e.target.nodeName == 'INPUT') {
                                    if (this.timeout) clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        this.getListData('reset');
                                    }, 300)
                                } else {
                                    this.getListData();
                                }
                            }}
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row tool-filter">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => {
                                            this.getListData('reset');
                                        }}>
                                            <Option value="">Trạng thái</Option>
                                            <Option value={0}>Đang xử lý</Option>
                                            <Option value={1}>Hoàn thành</Option>
                                            <Option value={2}>Thất bại</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="order_type">
                                        <Select onChange={() => this.getListData('reset')}>
                                            <Select.Option value="">Loại</Select.Option>
                                            {
                                                this.props.service.type_order ? this.props.service.type_order.map((item, i) => {
                                                    return item.value != "buff_order" ? <Select.Option key={i} value={item.value} >{item.name}</Select.Option> : null
                                                }) : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Xóa lọc">
                                        <Button className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getListData('reset');
                                        }}></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "Id",
                                        render: (item, value, index) => {
                                            return '#' + item.order_id
                                        }
                                    },
                                    {
                                        title: "Loại",
                                        dataIndex: "order_type"
                                    },
                                    {
                                        title: "Tổng tiền",
                                        render: (item) => {
                                            return this.props.formatPrice(item.total_price)
                                        }
                                    },
                                    {
                                        title: "Thành công",
                                        render: (item) => {
                                            return <div><span className="text-success">{item.num_success}</span> / {item.num_target}</div>
                                        }
                                    },
                                    {
                                        title: "Ghi chú",
                                        dataIndex: "note",
                                        render: (item) => <Input.TextArea value={item}></Input.TextArea>
                                    },
                                    {
                                        title: "Trạng thái",
                                        render: (item) => {
                                            return <Tag color={item.status == 0 || item.status == -1 ? "processing" : item.status == 1 ? "success" : "error"}>{item.status == -1 ? "Chờ xử lý" : item.status == 0 ? "Đang xử lý" : item.status == 1 ? "Thành công" : item.status == 2 ? "Thất bại" : "Không xác định"}</Tag>
                                        }
                                    },
                                    {
                                        title: "Ngày",
                                        render: (item) => {
                                            return moment(item.created_time).format('YYYY/MM/DD HH:mm')
                                        }
                                    },
                                    {
                                        title: "Hành động",
                                        className: "text-right",
                                        render: (item) => {
                                            return <div>
                                                <Tooltip title="Xem log">
                                                    <Button size="small" onClick={() => {
                                                        this.props.history.push(`/log?order_id=${item.order_id}`)
                                                    }}><i className="far fa-file-alt"></i></Button>
                                                </Tooltip>
                                            </div>
                                        }
                                    }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    onShowSizeChange: (current, size) => {
                                        this.page = current;
                                        this.limit = size;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: true
                                }}
                                rowKey="_id"
                                rowSelection={{
                                    type: "checkbox",
                                    selectedRowKeys: this.state.dataSelected,
                                    onChange: (values) => {
                                        this.setState({
                                            dataSelected: values
                                        })
                                    },
                                    getCheckboxProps: (record) => ({
                                        disabled: record.status == 1 || record.status == 2
                                    }),
                                }}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
