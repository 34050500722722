import React, { Component } from 'react';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, Radio, InputNumber } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled } from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { fetchData } from '../lib/apis';
import { formatPrice } from '../lib/helpers';

export default class Upgrade extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total_price_upgrade: 0,
            loading: false
        }
        this.form = null;
    }
    componentDidMount = () => {
        this.props.getDetailUser();
    }
    componentWillUpdate = (nextProps, nextState) => {
        
    }
    changeUpgrade = () => {
        let upgrade_num_uid = parseInt(this.form.getFieldValue("upgrade_num_uid")) || 0;
        let upgrade_num_phone = parseInt(this.form.getFieldValue("upgrade_num_phone")) || 0;
        this.setState({
            total_price_upgrade: (upgrade_num_uid * this.props.service.price_one_uid) + (upgrade_num_phone * this.props.service.price_one_phone)
        })
    }
    upgrade = async (values) => {
        try{
            this.props.showUpgrade();
            return
            this.setState({loading : true })
            let res = await fetchData({
                url: `api/v2/service/transaction`,
                method: 'post',
                data: JSON.stringify({
                    ...values,
                    type_package: 2
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.setState({loading : false }, () => {
                this.props.hideUpgrade();
                this.props.showDetailPayment(res.data);
            })
        } catch(err){
            this.setState({loading : false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Nâng cấp gói cước',
                type: 'error'
            })
        }
    }
    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="row row flex-column-reverse flex-md-row">
                    <div className="col-md-6">
                        <div className="block block-rounded">
                            <div className="block-header">
                                <h3 className="block-title">Thông tin cá nhân</h3>
                            </div>
                            <div className="block-content block-content-full text-center">
                                <img className="img-avatar img-avatar80 img-avatar-thumb" src={this.props.user.avatar} alt="" style={{ width: "80px", height: "80px" }} 
                                    onError={(e) => e.target.src = "/user.jpg"}
                                />
                            </div>
                            <div className="block-content">
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Họ và tên</label>
                                        <input type="text" className="form-control input-text" name="display_name" value={this.props.user.fullname} disabled />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Email</label>
                                        <input type="text" className="form-control input-text" name="display_name" value={this.props.user.email} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label className="label-title">Số điện thoại</label>
                                        <input type="text" className="form-control input-text" name="phone" value={this.props.user.phone} />
                                    </div>
                                </div>
                                {/* <div className="form-group row">
                                    <div className="col-12 text-center">
                                        <button className="btn btn-m">Lưu thông tin</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                        <div className="block block-rounded">
                            <div className="block-content">
                                <div className="p-15" style={{background: "rgb(239, 242, 245)", borderRadius: "5px"}}>
                                    <h5 className="title m-0">Bạn đang sử dụng gói: {this.props.user.package.name}</h5>
                                    <div className="pt-10 pb-10">
                                        <p className="m-0 mb-5">
                                            {
                                                this.props.user.package.max == -1 ? '- Không giới hạn số lượt buff' :
                                                `- Bạn còn ${formatPrice(this.props.user.package.num_view)} lượt buff`
                                            }
                                        </p>
                                        <p className="m-0 mb-5">
                                            - Ngày hết hạn: {moment(this.props.user.package.end_time).format('DD-MM-YYYY')}
                                        </p>
                                    </div>
                                    {
                                        this.props.user.package.end_time >= Date.now() ?
                                            <div>
                                                {
                                                    this.props.user.package.is_default == 1 ?
                                                        <div className="text-left">
                                                            <Button className="btn btn-noborder btn-primary" onClick={() => {
                                                                this.props.history.push('/package');
                                                            }} >
                                                                Nâng cấp gói cước
                                                        </Button>
                                                        </div> :
                                                        <div>
                                                            <Button type="primary" onClick={this.props.showUpgrade}>Mua thêm</Button>
                                                        </div>
                                                }
                                            </div> : null
                                    }
                                    {
                                        this.props.user.package.end_time < Date.now() ?
                                        <Button className="btn btn-noborder btn-danger" onClick={() => {
                                            this.props.history.push('/package');
                                        }} >
                                        Gói cước đã hết hạn nâng cấp ngay.
                                    </Button> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}
