import React from "react";
import NumberFormat from 'react-number-format';
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';

export default class ManageUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            detailBanking: null
        };
        this.limit = 15;
        this.page = 1;
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await fetchData({
                url: `api/v1/ow/user/list?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    update = async (values) => {
        try {
            this.setState({ confirmLoading: true })
            let res = await fetchData({
                url: `api/v1/ow/user/${values.user_id}`,
                method: 'post',
                data: values
            });
            this.getListData();
            this.setState({ confirmLoading: false })
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập người dùng',
                type: 'success'
            })
        } catch (err) {
            this.setState({ confirmLoading: false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập người dùng',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <Modal
                    title={'Chỉnh sửa quyền'}
                    visible={this.state.visibleFormRole}
                    onCancel={() => this.setState({ visibleFormRole: null })}
                    className={"form-modal form-code-company"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formUpdateRole = evt}
                        onFinish={this.update}
                    >
                        <Form.Item noStyle name="user_id">
                            <Input type="hidden" />
                        </Form.Item>
                        {
                            this.props.user.is_admin ? 
                            <Form.Item name="level"
                                label="Quyền"
                            >
                                <Select
                                    placeholder="Phân quyền"
                                >
                                    <Select.Option value={1}>User</Select.Option>
                                    <Select.Option value={98}>Manage</Select.Option>
                                    <Select.Option value={99}>Admin</Select.Option>
                                </Select>
                            </Form.Item> : null
                        }
                        <Form.Item name="status"
                            label="Trạng thái"
                        >
                            <Select
                                placeholder="Trạng thái"
                            >
                                <Select.Option value={1}>Hoạt động</Select.Option>
                                <Select.Option value={2}>Khóa</Select.Option>
                            </Select>
                        </Form.Item>
                        <div style={{ textAlign: "right" }}>
                            <Button onClick={() => this.setState({ visibleFormRole: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.confirmLoading}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Quản lý người dùng</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                            </div>
                            <div className={`${this.state.loading ? 'data-loading' : ''}`}>
                                <Pagination
                                    total={this.state.total}
                                    defaultCurrent={1}
                                    showSizeChanger={false}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getListData();
                                    }}
                                    simple
                                />
                            </div>
                        </div>
                        <Form
                            initialValues={{
                                is_user: "",
                                status: ""
                            }}
                            onChange={(e) => {
                                if (e.target.nodeName == 'INPUT') {
                                    if (this.timeout) clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        this.getListData('reset');
                                    }, 300)
                                } else {
                                    this.getListData();
                                }
                            }}
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row tool-filter">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="keyword">
                                        <Input placeholder="Từ khóa" />
                                    </Form.Item>
                                </div>
                                {
                                    this.props.user.is_admin ?
                                        <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                            <Form.Item name="is_user"
                                            >
                                                <Select onChange={(e) => {
                                                    this.getListData('reset');
                                                }}>
                                                    <Select.Option value="">level</Select.Option>
                                                    <Select.Option value={1}>Admin</Select.Option>
                                                    <Select.Option value={2}>User</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div> : null
                                }
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="status"
                                    >
                                        <Select onChange={(e) => {
                                            this.getListData('reset');
                                        }}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={1}>Hoạt động</Select.Option>
                                            <Select.Option value={2}>Tài khoản bị Khóa</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Xóa lọc">
                                        <Button className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getData('reset');
                                        }}></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>
                        <div className="table-responsive">
                            <Spin spinning={this.state.loading} tip="loading..">
                                <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Tên</th>
                                            <th>Số dư</th>
                                            <th>Email</th>
                                            {this.props.user.is_admin ? <th>Level</th> : ""}
                                            <th>Trạng thái</th>
                                            <th>Hoạt động</th>
                                            <th>Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                return <tr key={item._id}>
                                                    <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                    <td>{item.fullname}</td>
                                                    <td>{this.props.formatPrice(item.balance)}</td>
                                                    <td>{item.email}</td>
                                                    {
                                                        this.props.user.is_admin ? <td>{item.is_admin ? 'Admin' : item.is_manage ? 'Manage' : 'User'}</td> : null
                                                    }
                                                    <td>{item.status == 1 ? "Hoạt động" : <span className="text-danger">khóa</span>}</td>
                                                    <td>{item.last_time ? moment(item.last_time).format('HH:mm DD-MM-YYYY') : ''}</td>
                                                    <td>
                                                        <Tooltip title="Sửa quyền">
                                                            <Button disabled={item._id == this.props.user._id} size="small" onClick={() => {
                                                                this.setState({
                                                                    visibleFormRole: true
                                                                }, () => {
                                                                    setTimeout(() => {
                                                                        if (this.formUpdateRole) this.formUpdateRole.setFieldsValue({
                                                                            user_id: item._id,
                                                                            level: item.level,
                                                                            status: item.status
                                                                        })
                                                                    }, 100)
                                                                })
                                                            }}><i className="far fa-user-lock"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
