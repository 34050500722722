import React from "react";
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, Table } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';

export default class LogView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        };
        this.limit = 20;
        this.page = 1;
        this.client_id = this.props.match.params.client_id || "";
        let search = new URLSearchParams(this.props.location.search);
        this.order_id = search.get('order_id');
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit,
                client_id: this.client_id,
                order_id: this.order_id
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await fetchData({
                url: `api/v1/client/log/list?${qs.stringify(params)}`,
                method: 'get'
            });

            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }


    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Quản lý log</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <Form
                            initialValues={{
                                status: "",
                            }}
                            onChange={(e) => {
                                if (e.target.nodeName == 'INPUT') {
                                    if (this.timeout) clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        this.getListData('reset');
                                    }, 300)
                                } else {
                                    this.getListData();
                                }
                            }}
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row tool-filter">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="uid">
                                        <Input placeholder="Uid shopee"/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => {
                                            this.getListData('reset');
                                        }}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={0}>Đang xử lý</Select.Option>
                                            <Select.Option value={2}>Hoàn thành</Select.Option>
                                            <Select.Option value={3}>Thất bại</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Xóa lọc">
                                        <Button className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getListData('reset');
                                        }}></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>
                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "Id",
                                        render: (item, value, index) => {
                                            return (this.page * this.limit - this.limit) + index + 1
                                        }
                                    },
                                    {
                                        title: "Log id",
                                        dataIndex: "session_log_id"
                                    },
                                    {
                                        title: "Clien id",
                                        dataIndex: "client_id"
                                    },
                                    {
                                        title: "Uid",
                                        dataIndex: "uid"
                                    },
                                    {
                                        title: "Trạng thái",
                                        render: (item) => {
                                            return item.status == 0 ? <span>Đang chờ</span> : item.status == 2 ? <span>Thành công</span> : <span>Thất bại</span>
                                        }
                                    },
                                    {
                                        title: "Log",
                                        render: (item) => {
                                            item.logs.sort((a, b) => b.time - a.time);
                                            let logs = item.logs.map((item) => {
                                                return moment(item.time).format('YYYY-MM-DD HH:mm:ss') + ' -----> ' + item.message
                                            });
                                            return <Input.TextArea style={{ minHeight: "150px", minWidth: "250px" }} value={logs.join('\n')} />
                                        }
                                    },
                                    {
                                        title: "Thời gian",
                                        render: (item) => {
                                            return moment(item.created_time).format('YYYY/MM/DD HH:mm')
                                        }
                                    }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    onShowSizeChange: (current, size) => {
                                        this.page = current;
                                        this.limit = size;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: true
                                }}
                                rowKey="_id"
                                // rowSelection={{
                                //     type: "checkbox",
                                //     selectedRowKeys: this.state.dataSelected,
                                //     onChange: (values) => {
                                //         this.setState({
                                //             dataSelected: values
                                //         })
                                //     }
                                // }}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
