import React from "react";
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, DatePicker } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import qs from 'qs';
import Chart from 'chart.js/auto';
import locale from 'antd/es/date-picker/locale/vi_VN';

export default class StatitiscalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statitiscal: [],
            total: null
        };
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {

            }
            let date = this.formDate ?  this.formDate.getFieldValue('date') : null;

            if(date){
                params['start'] = date[0].format('YYYY-MM-DD');
                params['end'] = date[1].format('YYYY-MM-DD');
            }
            let response = await fetchData({
                url: `api/v1/statitiscal?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                statitiscal: response.data,
                total: response.total,
                loading: false
            }, () => {
                this.showChart(response.data);
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    showChart = (options) => {
        var config = {
            type: 'line',
            data: {
                labels: options.map((item) => item.date),
                datasets: [{
                    label: '# Người dùng',
                    data: options.map((item) => item.total_user),
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1
                },
                {
                    label: '# Giao dịch',
                    data: options.map((item) => item.total_transaction),
                    backgroundColor: ["rgba(255,0,0,0.2)"],
                    borderColor: ["rgba(255,0,0,1)"],
                    borderWidth: 1
                }
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        }
        if (!window.myLine) {
            var ctx = document.getElementById('myChart').getContext('2d');
            console.log(ctx);
            window.myLine = new Chart(ctx, config);
        } else {
            document.getElementById('myChart').remove();
            document.getElementById('block-myChart').innerHTML = '<canvas id="myChart" width="400" height="120"></canvas>';
            var ctx = document.getElementById('myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        }

    }

    render() {
        let { total } = this.state;
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded" style={{ minHeight: "auto" }}>
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m" >Thống kê</h3>
                        </div>
                    </div>
                </div>
                <div className="block block-rounded" style={{ minHeight: "auto", background: "transparent" }}>
                    <div class="row">
                        <div class="col-6 col-xl-3">
                            <a class="block block-rounded block-bordered block-link-shadow" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-right mt-15 d-none d-sm-block">
                                        <i class="si si-users fa-2x text-pulse"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-pulse" >{total ? total.total_user : 0}</div>
                                    <div class="font-size-sm font-w600 text-uppercase text-muted">Người dùng</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-6 col-xl-3">
                            <a class="block block-rounded block-bordered block-link-shadow" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-right mt-15 d-none d-sm-block">
                                        <i class="si si-wallet fa-2x text-earth-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-earth"><span>{total ? <NumberFormat value={total.total_amount} displayType={'text'} thousandSeparator={true} /> : 0} đ</span></div>
                                    <div class="font-size-sm font-w600 text-uppercase text-muted">Thu nhập</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-6 col-xl-3">
                            <a class="block block-rounded block-bordered block-link-shadow" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-right mt-15 d-none d-sm-block">
                                        <i class="si si-bag fa-2x text-primary-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-primary" >{total ? total.total_transaction : 0}</div>
                                    <div class="font-size-sm font-w600 text-uppercase text-muted">Giao dịch</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-6 col-xl-3">
                            <a class="block block-rounded block-bordered block-link-shadow" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-right mt-15 d-none d-sm-block">
                                        <i class="si si-envelope-open fa-2x text-elegance-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-elegance" >{total ? total.total_order : 0}</div>
                                    <div class="font-size-sm font-w600 text-uppercase text-muted">Đơn hàng</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="block block-rounded" style={{ minHeight: "auto" }}>
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m" >Người dùng</h3>
                        </div>
                        <div>
                            <Form
                                ref={(evt) => this.formDate = evt }
                            >
                                <Form.Item name="date">
                                    <DatePicker.RangePicker
                                        locale={locale}
                                        defaultValue={[moment().add(-7, 'day'), moment()]}
                                        format={'DD-MM-YYYY'}
                                        ranges={{
                                            'Tuần này': [moment().startOf("week").add(1, "day"), moment().endOf("week").add(1, "day")],
                                            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                            'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                        }}
                                        onChange={(moments, dateString) => {
                                            this.getListData();
                                        }}
                                        format="DD/MM/YYYY"
                                        allowClear={false}

                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <Spin spinning={this.state.loading} tip="loading..">
                        <div className="block-content" id="block-myChart">
                            <canvas id="myChart" width="400" height="120"></canvas>
                        </div>
                    </Spin>
                </div>
            </div>
        );
    }
}
