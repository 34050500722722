import React from "react";
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';

export default class LogView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        };
        this.limit = 20;
        this.page = 1;
        let search = new URLSearchParams(this.props.location.search);
        this.order_id = search.get('order_id');
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await fetchData({
                url: `api/v1/order/${this.order_id}/shop_order?${qs.stringify(params)}`,
                method: 'get'
            });

            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }


    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Kết quả đơn hàng</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                            </div>
                            <div className={`${this.state.loading ? 'data-loading' : ''}`}>
                                <Pagination
                                    total={this.state.total || 1}
                                    defaultCurrent={1}
                                    showSizeChanger={true}
                                    pageSizeOptions={[20, 50, 100, 200, 500]}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getListData();
                                    }}
                                    onShowSizeChange={(current, size) => {
                                        this.limit = size;
                                        this.page = current;
                                        this.getListData();
                                    }}
                                />
                            </div>
                        </div>
                        <Form
                            initialValues={{
                                status: "",
                                rating_status: ""
                            }}
                            onChange={(e) => {
                                if (e.target.nodeName == 'INPUT') {
                                    if (this.timeout) clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        this.getListData('reset');
                                    }, 300)
                                } else {
                                    this.getListData();
                                }
                            }}
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row tool-filter">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="uid">
                                        <Input placeholder="Uid shopee"/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => {
                                            this.getListData('reset');
                                        }}>
                                            <Select.Option value="">TT đặt hàng</Select.Option>
                                            <Select.Option value={0}>Đang xử lý</Select.Option>
                                            <Select.Option value={1}>Thanh toán</Select.Option>
                                            <Select.Option value={2}>Hoàn thành</Select.Option>
                                            <Select.Option value={3}>Thất bại</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="rating_status">
                                        <Select onChange={(e) => {
                                            this.getListData('reset');
                                        }}>
                                            <Select.Option value="">TT đánh giá</Select.Option>
                                            <Select.Option value={1}>Đang chờ</Select.Option>
                                            <Select.Option value={2}>Hoàn thành</Select.Option>
                                            <Select.Option value={3}>Thất bại</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Xóa lọc">
                                        <Button className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getListData('reset');
                                        }}></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>
                        <div className="table-responsive">

                            <Spin spinning={this.state.loading} tip="loading..">
                                <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                    <thead>
                                        <tr>
                                            <th className="text-left">#</th>
                                            <th>Log id</th>
                                            <th>Clien id</th>
                                            <th>UID</th>
                                            <th>TT đặt hàng</th>
                                            <th>Đánh giá</th>
                                            <th>Log</th>
                                            <th>Thời gian</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                item.logs.sort((a, b) => b.time - a.time);
                                                let logs = item.logs.map((item) => {
                                                    return moment(item.time).format('YYYY-MM-DD HH:mm:ss')+' -----> '+ item.message
                                                });
                                                return <tr key={item._id}>
                                                    <td className="text-left">{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                    <td>{item.session_log_id}</td>
                                                    <td>{item.client_id}</td>
                                                    <td>{item.uid}</td>
                                                    <td>
                                                        { item.status == 0 ? <span>Chờ xử lý</span> : item.status == 1 ? <span>Đang xử lý</span> : item.status == 2 ? <span>Thành công</span> : <span>Thất bại</span>}
                                                    </td>
                                                    <td>
                                                        {
                                                            item.status == 2 ?
                                                            <div>{ item.rating_status == 0 ? <span>Không đánh giá</span> : item.rating_status == 1 ? <span>Đang chờ</span> : item.rating_status == 2 ? <span>Thành công</span> : <span>Thất bại</span>}</div> : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <Input.TextArea style={{minHeight: "150px", minWidth: "250px"}} value={logs.join('\n')}/>
                                                    </td>
                                                    <td>{item.created_time ? moment(item.created_time).format('HH:mm DD-MM-YYYY') : ''}</td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
